import BackArrow from "common/icons/backArrow.svg";
import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { CommonHeading, IconButton } from "./StyledElements";

const Img = styled.img`
  height: 25px;
  width: 20px;
`;
const TitleContainer = styled.div`
  display: flex;
`;

const HeadingWithBackButton = (props) => {
  const navigate = useNavigate();
  const { heading } = props;
  return (
    <TitleContainer>
      <IconButton type="button" onClick={() => navigate(-1)}>
        <Img src={BackArrow} alt="back" />
      </IconButton>
      <CommonHeading>{heading}</CommonHeading>
    </TitleContainer>
  );
};

export default HeadingWithBackButton;

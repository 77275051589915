import COLORS from "common/constants/Colors";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { Table as AntdTable, Modal, Button as AntdButton, Input } from "antd";

const { Search: AntdSearch } = Input;

export const LinkElement = styled(Link)`
  margin: 0 20px 0 0;
`;

export default {
  LinkElement
};

export const CommonHeading = styled.div`
  font-weight: bold;
  font-size: 16px;
  margin: 20px 0;
`;

export const Button = styled(AntdButton)`
  background: "white";
  color: ${COLORS.primary_green};
  font-size: 14px;
  font-weight: bold;
  min-width: 60px;
  max-width: 420px;
  padding: 0.25em 1em;
  border: 1.5px solid ${COLORS.primary_green};
  border-radius: 5px;
  ${(props) =>
    props.primary &&
    css`
      color: white;
      background: ${COLORS.primary_green};
      font-size: 16px;
      font-weight: 600;
      height: 50px;
      :hover {
        background: ${COLORS.primary_green};
        color: white;
      }
    `}
`;

export const SearchElement = styled(AntdSearch)`
  max-width: 40%;
`;

export const Table = styled(AntdTable)`
  .ant-table table {
    border-collapse: collapse;
    margin: 20px 0px;
    border: 1px solid #d4d4d4;
    border-radius: 10px;
    > :last-child {
      border: 0px solid black;
      border-bottom: 1.2px solid #d4d4d4;
    }
  }
  thead {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    overflow: hidden;
  }
  tr {
    align-items: center;
    justify-items: center;
    border-bottom: 1px solid #d4d4d4;
  }
`;
export const CustomAntdTable = styled(AntdTable)`
  width: 100%;
  .ant-table-container {
    border: 1px solid ${COLORS.primary_grey} !important;
  }
  .ant-table-thead > tr > th {
    background-color: white;
    text-transform: capitalize;
    border-bottom: 1px solid ${COLORS.primary_grey};
  }
  .ant-table-tbody > tr > td {
    border-bottom: 1px solid ${COLORS.primary_grey};
  }
  .ant-table-tbody > tr.ant-table-row:hover > td,
  .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background: white;
  }
  .ant-table-container table > tbody > tr:last-child > td {
    border-bottom: 0px !important;
  }
`;
export const Container = styled.section`
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 20px;
  padding-bottom: 50px;
`;

export const SearchHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const LoginFormContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 25%;
  margin: 100px;
`;

export const LargeButton = styled(Button)`
  height: 70px;
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  border: 1.5px solid ${COLORS.primary_green};
  border-radius: 6px;
  margin-top: 20px;
  :disabled {
    color: white;
    background: ${COLORS.primary_green};
    opacity: 0.7;
    border: 1.5px solid ${COLORS.primary_green};
    border-radius: 6px;
    :hover {
      background: ${COLORS.primary_green};
      color: white;
    }
  }
`;

export const InputElement = styled.input`
  height: 50px;
  border: 1.5px solid ${COLORS.primary_green};
  border-radius: 6px;
  padding: 16px;
  margin-bottom: 10px;
  outline: none;
`;

export const TitleElement = styled.h1`
  font-size: 18px;
  line-height: 23px;
  color: #000000;
  font-weight: bold;
  letter-spacing: 0;
`;

export const TextElement = styled.p`
  font-size: 16px;
`;

export const TextElementCursor = styled.p`
  font-size: 16px;
  cursor: pointer;
`;
export const ErrorElement = styled.h6`
  font-size: 10px;
  color: red;
`;

export const PageHeader = styled.div`
  font-weight: bold;
  font-size: 22px;
  color: black;
`;

export const PageContainer = styled.div`
  margin: 10px;
  margin-left: 20px;
`;

export const IconButton = styled.button`
  background: white;
  border: 0px;
  cursor: pointer;
`;

export const Seperator = styled.hr`
  margin-top: 30px;
  margin-left: 0px;
  width: 90%;
  border: 1px solid ${COLORS.primary_grey};
`;

export const ErrorMessage = styled.div`
  margin-top: 5px;
  color: red;
`;

export const CommonTableContainer = styled.div`
  margin: 20px 0px;
  border: 1px solid ${COLORS.primary_grey};
  border-radius: 10px;
  width: 90%;
  max-height: 70vh;
  overflow: auto;
  > :last-child {
    border: 0px solid black;
  }
`;

export const CommonTableInput = styled.input`
  border: 1px solid ${COLORS.primary_grey};
  border-radius: 4px;
  text-align: center;
  width: 85%;
`;

export const CommonSearchInput = styled.input`
  border: 1px solid ${COLORS.primary_grey};
  border-radius: 4px;
  padding: 10px;
  width: 25%;
  height: 35px;
`;
export const ImageContainer = styled.div`
  margin-top: 20px;
`;
export const ImageInputElement = styled.input`
  display: flex;
  flex-wrap: wrap;
  height: 68px;
  width: 68px;
  border: 1.5px solid ${COLORS.primary_green};
  border-radius: 6px;
  padding: 16px;
  margin-bottom: 10px;
  ${(props) =>
    props.disabled &&
    css`
      background-color: ${COLORS.disabled_grey};
      border-color: ${COLORS.disabled_grey};
    `}
`;

export const ImageElement = styled.img`
  display: flex;
  flex-wrap: wrap;
  height: 68px;
  width: 68px;
  border: 1.5px solid ${COLORS.primary_green};
  border-radius: 6px;
  margin-bottom: 10px;
`;
export const CommonTableHeading = styled.div`
  font-size: 16px;
  font-weight: 500;
`;

export const CommonTableHeader = styled.div`
  display: grid;
  padding: 20px;
  align-items: center;
  text-align: center;
  justify-items: center;
  grid-template-columns: ${(props) => props.gridTemplateColumns};
  border-bottom: 1px solid ${COLORS.primary_grey};
  position: sticky;
  top: 0px;
  background: white;
`;
export const CommonTableRowContainer = styled.div`
  display: grid;
  padding: 20px;
  align-items: center;
  text-align: center;
  justify-items: center;
  grid-template-columns: ${(props) => props.gridTemplateColumns};
  border-bottom: 1px solid ${COLORS.primary_grey};
`;
export const ColorAntdButton = styled(Button)`
  border-color: ${(props) => props.color || COLORS.primary_green};
  color: ${(props) => props.color || COLORS.primary_green};
  :hover {
    border-color: ${(props) => props.color || COLORS.primary_green};
    color: ${(props) => props.color || COLORS.primary_green};
  }
`;
export const SelectContainer = styled.div`
  margin-left: 1em;
  display: flex;
`;

export const CustomAntdModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 20px;
  }
  .ant-modal-header {
    border-radius: 20px 20px 0 0;
  }
`;
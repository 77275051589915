import { checkPermission } from "./index";

export const PERMISSION_STRATEGIES = {
  simple: "simple",
  atLeastOneTrue: "atLeastOneTrue",
  placeOrder: "placeOrder"
};

export const simpleStrategy = (parsedRoleConfig, permission) =>
  parsedRoleConfig && checkPermission(parsedRoleConfig, permission);

export const atLeastOneTrueStrategy = (parsedRoleConfig, permissions = []) =>
  parsedRoleConfig &&
  permissions.some((permission) =>
    checkPermission(parsedRoleConfig, permission)
  );

export const getPermissionStrategy = (strategy) => {
  switch (strategy) {
    case PERMISSION_STRATEGIES.placeOrder:
    case PERMISSION_STRATEGIES.simple:
      return simpleStrategy;
    case PERMISSION_STRATEGIES.atLeastOneTrue:
      return atLeastOneTrueStrategy;
    default:
      return simpleStrategy;
  }
};

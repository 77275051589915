import { useAuthContext } from "common/contexts/AuthContext";
import { getPermissionStrategy } from "common/utils/permissionStrategies";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const useCheckAuthorization = (permissions, strategy = "simple") => {
  const { parsedRoleConfig } = useAuthContext();
  const checkIfAuthorized = getPermissionStrategy(strategy);
  const navigate = useNavigate();

  useEffect(() => {
    if (!checkIfAuthorized(parsedRoleConfig, permissions)) {
      navigate("/dashboard");
    }
  }, [checkIfAuthorized, navigate, parsedRoleConfig, permissions]);

  return checkIfAuthorized(parsedRoleConfig, permissions);
};

export default useCheckAuthorization;

import { message } from "antd";
import {
  Button,
  ColorAntdButton,
  CommonHeading,
  CommonSearchInput,
  CustomAntdTable,
  PageContainer,
  SearchHeader
} from "common/components/StyledElements";
import { PERMISSIONS, ROLE_CONFIG_KEYS } from "common/constants";
import APIConstants from "common/constants/APIConstants";
import COLORS from "common/constants/Colors";
import { useAuthContext } from "common/contexts/AuthContext";
import { checkPermission } from "common/utils";
import { api } from "common/utils/APIMethods";
import { useMemo, useRef } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const ButtonsContainer = styled.div`
  max-width: 250px;
  display: flex;
  justify-content: space-around;
`;
const ButtonsSelfContainer = styled.div`
  margin-right: auto;
`;
const GroupsList = (props) => {
  const navigate = useNavigate();
  const {
    groups = {},
    isGroupsLoading,
    searchText,
    setSearchText,
    setPagination,
    pagination,
    refetch
  } = props;
  const { authConfig, parsedRoleConfig } = useAuthContext();
  const { userGroupURL } = APIConstants;
  const deletingGroup = useRef("");

  const { isLoading: isDeleting, mutate } = useMutation(
    (groupId) =>
      api({ url: `${userGroupURL}${groupId}`, method: "DELETE" }, authConfig),
    {
      onSuccess: () => {
        deletingGroup.current = "";
        message.success("Users group has been deleted successfully");
        refetch();
      },
      onError: (err) => {
        message.error(
          err?.message ||
            "There was some error while deleting the group, Please try again."
        );
      }
    }
  );

  const handleView = (group) => {
    navigate(`details/${group.key}`);
  };

  const handleEdit = (group) => {
    navigate("create", {
      state: { groupId: group.key, isEditMode: true }
    });
  };

  const ifShowEdit = checkPermission(parsedRoleConfig, {
    config: ROLE_CONFIG_KEYS.group,
    permission: PERMISSIONS.update
  });
  const ifShowDelete = checkPermission(parsedRoleConfig, {
    config: ROLE_CONFIG_KEYS.group,
    permission: PERMISSIONS.delete
  });

  const columns = [
    {
      title: "Group Name",
      dataIndex: "groupName"
    },
    {
      title: "Owner",
      dataIndex: "contactPerson"
    },
    {
      title: "Users",
      dataIndex: "usersCount"
    },
    {
      title: "Actions",
      dataIndex: "",
      render: (item) => (
        <ButtonsContainer>
          <ButtonsSelfContainer>
            <Button disabled={isDeleting} onClick={() => handleView(item)}>
              View
            </Button>
          </ButtonsSelfContainer>
          <ButtonsSelfContainer>
            {ifShowEdit && (
              <ColorAntdButton
                disabled={isDeleting}
                color={COLORS.action_review}
                onClick={() => handleEdit(item)}
              >
                Edit
              </ColorAntdButton>
            )}
          </ButtonsSelfContainer>
          <ButtonsSelfContainer>
            {ifShowDelete && (
              <ColorAntdButton
                loading={item.key === deletingGroup.current && isDeleting}
                disabled={isDeleting}
                color={COLORS.action_decline}
                onClick={() => {
                  deletingGroup.current = item.key;
                  mutate(item.key);
                }}
              >
                Delete
              </ColorAntdButton>
            )}
          </ButtonsSelfContainer>
        </ButtonsContainer>
      )
    }
  ];

  const tableData = useMemo(
    () =>
      groups?.content?.map((value) => {
        const {
          id,
          name,
          members = [],
          owner: { contactPerson = "" }
        } = value;
        return {
          key: id,
          groupName: name,
          contactPerson,
          usersCount: members.length
        };
      }),
    [groups]
  );

  const ifShowCreateGroup = checkPermission(parsedRoleConfig, {
    config: ROLE_CONFIG_KEYS.group,
    permission: PERMISSIONS.create
  });

  return (
    <PageContainer>
      <CommonHeading>User Groups</CommonHeading>
      <SearchHeader>
        <CommonSearchInput
          placeholder="Search group name..."
          value={searchText}
          onChange={(e) => {
            setPagination({
              currentPage: 0,
              pageSize: pagination.pageSize
            });
            setSearchText(e.target.value);
          }}
        />
        {ifShowCreateGroup && (
          <Button primary="true" onClick={() => navigate("create")}>
            Create Group
          </Button>
        )}
      </SearchHeader>
      <br />
      <CustomAntdTable
        loading={isGroupsLoading}
        columns={columns}
        dataSource={tableData}
        scroll={{ y: "calc(60vh)" }}
        pagination={{
          pageSize: pagination.pageSize,
          total: groups?.totalElements,
          showSizeChanger: true,
          pageSizeOptions: ["10", "20", "30", "40", "50"],
          onChange: (changedPage, pageSize) => {
            setPagination({
              currentPage: changedPage - 1,
              pageSize
            });
          }
        }}
      />
    </PageContainer>
  );
};

export default GroupsList;

const baseURL =
  process.env.REACT_APP_URL_ENV === "GAMMA"
    ? "https://beta.valuemedi.com/"
    : "https://www.valuemedi.com/";

const urlDirect = process.env.REACT_APP_URL_ENV === "GAMMA" ? "GAMMA" : "PROD";

const defaultHeaders = {
  Accept: "application/json",
  "Content-Type": "application/json"
};

const loginURL = `${baseURL}api/auth/login`;
const allUserListURL = `${baseURL}api/v1/user/all-users`;
const allUserStatusURL = `${baseURL}api/v1/user/all-user-statuses`;
const getCouponRule = `${baseURL}api/v1/coupon/fetchRule`;
const getCouponCases = `${baseURL}api/v1/coupon/fetchCases`;
const createCouponRule = `${baseURL}api/v1/coupon/createRule`;
const createCouponCases = `${baseURL}api/v1/coupon/createCases`;
const createCoupons = `${baseURL}api/v1/coupon/createCoupons`;
const userProfile = `${baseURL}api/v1/user/profile`;
const userAllRole = `${baseURL}api/v1/user/all-roles`;
const allStates = `${baseURL}api/v1/utils/states`;
const allDistricts = `${baseURL}api/v1/utils/districts`;
const updateUserProfile = `${baseURL}api/v1/user/update-profile`;
const createUser = `${baseURL}api/v1/user/create`;
const sendErpCredentials = `${baseURL}api/v1/erp-credentials/persist`;
const getErpCredentials = `${baseURL}api/v1/erp-credentials`;
const getRepresentativeById = `${baseURL}api/v1/representative/list`;
const userListById = `${baseURL}api/v1/user/list`;
const associateRepresentative = `${baseURL}api/v1/representative/associate`;
const settingsFetch = `${baseURL}api/v1/settings/fetch`;
const settingsUpdate = `${baseURL}api/v1/settings/update`;
const notifyRetailer = `${baseURL}api/v1/settings/notify`;
const userGroups = `${baseURL}api/v1/userGroup/all`;
const userGroupsList = `${baseURL}api/v1/userGroup/list`;
const userGroupURL = `${baseURL}api/v1/userGroup/`;
const persistUserGroup = `${baseURL}api/v1/userGroup/persist`;
const createUserCoupon = `${baseURL}api/v1/coupon/add`;
const getErpList = `${baseURL}api/v1/erp-credentials/supported-erp`;
const refreshMarg = `${baseURL}api/marg/masters/refresh-marg-masters`;
const clearMarg = `${baseURL}api/marg/masters/clear-marg-masters`;
const refreshDB = `${baseURL}api/v1/utils/refresh-db`;
const uploadDrugLicense = `${baseURL}api/v1/user/drug-license`;
const bulkOnboarding = `${baseURL}api/v1/user/bulk-onboarding`;
const getAllSubscriptions = `${baseURL}api/v1/subscriptions/all`;
const createSubscription = `${baseURL}api/v1/subscriptions/create`;
const updateSubscription = `${baseURL}api/v1/subscriptions/update`;
const deleteSubscription = `${baseURL}api/v1/subscriptions`;
const supplierCustomerIngestionUrl = `${baseURL}api/v1/customers/ingestion`;
const uploadBroadcasts = `${baseURL}api/v1/broadcast/parse-broadcasts`;
const broadcastsDetails = `${baseURL}api/v1/broadcast/broadcasts`;
const broadcastTemplates = `${baseURL}api/v1/broadcast/broadcast-templates`;
const cfaIngestion = `${baseURL}api/v1/sap/process-masters`;
const inventoryProducts = `${baseURL}api/v1/inventory/admin/search/`;
const inventoryList = `${baseURL}api/v1/admin/inventory/grid`;
const saveInventoryChanges = `${baseURL}api/v1/admin/inventory/save`;
const inventoryTrigger = `${baseURL}api/v1/admin/inventory/group`;
const inventoryCombine = `${baseURL}api/v1/admin/inventory/combine`;
const broadcastLimit = `${baseURL}api/v1/broadcast/broadcast-limit`;

const APIConstants = {
  defaultHeaders,
  baseURL,
  loginURL,
  allUserListURL,
  allUserStatusURL,
  createCouponRule,
  getCouponCases,
  getCouponRule,
  createCouponCases,
  createCoupons,
  userProfile,
  userAllRole,
  updateUserProfile,
  allStates,
  allDistricts,
  createUser,
  sendErpCredentials,
  getErpCredentials,
  getRepresentativeById,
  userListById,
  urlDirect,
  settingsFetch,
  settingsUpdate,
  associateRepresentative,
  userGroups,
  userGroupURL,
  userGroupsList,
  persistUserGroup,
  createUserCoupon,
  getErpList,
  refreshMarg,
  clearMarg,
  refreshDB,
  uploadDrugLicense,
  notifyRetailer,
  bulkOnboarding,
  getAllSubscriptions,
  createSubscription,
  updateSubscription,
  deleteSubscription,
  supplierCustomerIngestionUrl,
  uploadBroadcasts,
  broadcastsDetails,
  broadcastTemplates,
  cfaIngestion,
  inventoryProducts,
  inventoryList,
  saveInventoryChanges,
  inventoryTrigger,
  inventoryCombine,
  broadcastLimit
};

export default APIConstants;

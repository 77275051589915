import HeadingWithBackButton from "common/components/HeadingWithBackButton";
import {
  CommonSearchInput,
  CustomAntdTable,
  PageContainer
} from "common/components/StyledElements";
import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";

const columns = [
  {
    title: "Party Name",
    dataIndex: "user"
  },
  {
    title: "Drug License",
    dataIndex: "drugLicense"
  },
  {
    title: "UID",
    dataIndex: "uid"
  },
  {
    title: "City",
    dataIndex: "city"
  },
  {
    title: "Contact",
    dataIndex: "phoneNumber"
  }
];

const GroupDetails = (props) => {
  const { id: groupId } = useParams();
  const { groups = {} } = props;
  const [searchText, setSearchText] = useState("");
  const groupDetails = useMemo(
    () =>
      groups?.content?.filter(
        (value) => value.id === parseInt(groupId, 10)
      )?.[0],
    [groups, groupId]
  );
  const tableData = useMemo(() => {
    const groupMembers = groupDetails?.members?.filter(
      (value) =>
        value?.customer?.businessName?.toLowerCase().includes(searchText) ||
        value?.customer?.address.city?.toLowerCase().includes(searchText)
    );
    return groupMembers?.map((value) => {
      const {
        id,
        businessName,
        uuid,
        address: { city },
        phoneNumber,
        drugLicense
      } = value.customer || {};
      return {
        key: id,
        user: businessName,
        drugLicense,
        uid: uuid,
        city,
        phoneNumber
      };
    });
  }, [groupDetails, searchText]);
  return (
    <PageContainer>
      <HeadingWithBackButton
        heading={`Group Name: ${groupDetails?.name || ""}`}
      />
      <CommonSearchInput
        placeholder="Search by business name or city…"
        value={searchText}
        onChange={(e) => {
          setSearchText(e.target.value.toLowerCase());
        }}
      />
      <br />
      <br />
      <CustomAntdTable
        columns={columns}
        dataSource={tableData}
        scroll={{ y: "calc(60vh)" }}
        pagination={false}
      />
    </PageContainer>
  );
};

export default GroupDetails;

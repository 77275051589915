import APIConstants from "common/constants/APIConstants";
import { useAuthContext } from "common/contexts/AuthContext";
import { api } from "common/utils/APIMethods";
import { useState } from "react";
import { useQuery } from "react-query";
import { Route, Routes } from "react-router-dom";
import useCheckAuthorization from "common/hooks/useCheckAuthorization";
import { PERMISSIONS, ROLE_CONFIG_KEYS } from "common/constants";
import CreateGroup from "./CreateGroup";
import GroupDetails from "./GroupDetails";
import GroupsList from "./GroupsList";

const UserGroups = () => {
  const { authConfig } = useAuthContext();
  const { userGroups } = APIConstants;
  const [pagination, setPagination] = useState({
    currentPage: 0,
    pageSize: 20
  });
  const [searchText, setSearchText] = useState("");

  useCheckAuthorization({
    config: ROLE_CONFIG_KEYS.group,
    permission: PERMISSIONS.read
  });

  const fetchGroups = () =>
    api(
      {
        url: userGroups,
        params: {
          query: searchText,
          page: pagination.currentPage,
          size: pagination.pageSize
        }
      },
      authConfig
    );

  const {
    data: groups,
    isLoading: isGroupsLoading,
    refetch
  } = useQuery(
    ["groups", pagination.currentPage, pagination.pageSize, searchText],
    fetchGroups,
    {
      keepPreviousData: true
    }
  );

  return (
    <Routes>
      <Route
        index
        path="/"
        element={
          <GroupsList
            groups={groups}
            refetch={refetch}
            isGroupsLoading={isGroupsLoading}
            searchText={searchText}
            setSearchText={setSearchText}
            setPagination={setPagination}
            pagination={pagination}
          />
        }
      />
      <Route
        path="create"
        element={<CreateGroup groups={groups} refetch={refetch} />}
      />
      <Route path="details/:id" element={<GroupDetails groups={groups} />} />
    </Routes>
  );
};

export default UserGroups;

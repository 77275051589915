import { Button, InputElement } from "common/components/StyledElements";
import { message } from "antd";
import APIConstants from "common/constants/APIConstants";
import { useMutation } from "react-query";
import { api } from "common/utils/APIMethods";
import { useAuthContext } from "common/contexts/AuthContext";
import styled from "styled-components";
import { useState } from "react";

const Container = styled.div`
  margin-left: 20px;
`;

const HorizontalContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

const FieldContainer = styled.div`
  margin-right: 40px;
  height: 100px;
  width: 250px;
`;
const ButtonContainer = styled.div`
  max-width: 250px;
  display: flex;
  justify-content: space-around;
`;
const ButtonsSelfContainer = styled.div`
  margin-right: 1em;
  margin-left: auto;
`;

const MargRefresh = () => {
  const { authConfig } = useAuthContext();
  const { refreshMarg, clearMarg } = APIConstants;
  const [distributorId, setDistributorId] = useState("");
  const { isLoading, mutate: clearMargMutation } = useMutation(
    () =>
      api(
        {
          url: `${clearMarg}/${distributorId}`,
          method: "PUT"
        },
        authConfig
      ),
    {
      onSuccess: (response) => {
        message.success(response);
      },
      onError: (err) => {
        message.error(
          err?.message || "There was some error, Please try again."
        );
      }
    }
  );
  const { isLoading: refreshingMarg, mutate: refreshMargMutation } = useMutation(
    () =>
      api(
        {
          url: `${refreshMarg}/${distributorId}`,
          method: "PUT"
        },
        authConfig
      ),
    {
      onSuccess: (response) => {
        message.success(response);
        setDistributorId("");
      },
      onError: (err) => {
        message.error(
          err?.message || "There was some error, Please try again."
        );
      }
    }
  );
  return (
    <Container key="Container">
      <HorizontalContainer>
        <FieldContainer>
          <InputElement
            onChange={(distributorData) => {
              setDistributorId(distributorData?.target?.value);
            }}
            allowClear
            value={distributorId}
            type="phone"
            maxLength={10}
            placeholder="Enter Distributor Id"
          />
        </FieldContainer>
        <FieldContainer>
          <ButtonContainer>
            <ButtonsSelfContainer>
              <Button
                primary="true"
                onClick={() => clearMargMutation()}
                loading={isLoading}
                disabled={!distributorId}
              >
                Clear Marg
              </Button>
            </ButtonsSelfContainer>
            <ButtonsSelfContainer>
              <Button
                primary="true"
                onClick={() => refreshMargMutation()}
                loading={refreshingMarg}
                disabled={!distributorId}
              >
                Refresh Marg
              </Button>
            </ButtonsSelfContainer>
          </ButtonContainer>
        </FieldContainer>
      </HorizontalContainer>
      <p>
        * Marg clear deletes the inventory and ledger reports from the job
        execution report of the selected distributor.
      </p>
      <p>* Marg refresh refetches the data of the supplier from marg</p>
    </Container>
  );
};

export default MargRefresh;

import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuthContext } from "common/contexts/AuthContext";
import {
  LoginFormContainer,
  LargeButton,
  InputElement,
  TitleElement,
  ErrorElement
} from "common/components/StyledElements";
import APIConstants from "common/constants/APIConstants";
import { api } from "common/utils/APIMethods";
import { useEffect } from "react";

const Login = () => {
  const navigate = useNavigate();
  const locationProps = useLocation();
  const { dispatch, authConfig } = useAuthContext();
  const { loginURL } = APIConstants;

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue
  } = useForm({
    mode: "onChange",
    defaultValues: {
      username: locationProps?.state?.username || "",
      password: ""
    }
  });

  useEffect(() => {
    if (authConfig.sessionId) {
      navigate("/dashboard", { replace: true });
    }
  }, [authConfig.sessionId, navigate]);

  const { isLoading, isError, error, mutate } = useMutation(
    (formData) =>
      api({
        url: loginURL,
        method: "POST",
        body: formData
      }),
    {
      onSuccess: (response) => {
        dispatch({ type: "onLogin", payload: response });
        navigate("/dashboard", { replace: true });
      }
    }
  );

  const onSubmit = (formData) => {
    mutate(formData);
  };
  const handleEnter = (event) => {
    if (event.key === "Enter") {
      handleSubmit(onSubmit)();
    }
  };
  return (
    <div>
      <LoginFormContainer>
        <TitleElement>Welcome To Ennea</TitleElement>
        <TitleElement>User Name</TitleElement>
        <InputElement
          {...register("username", { required: "Enter your username" })}
          placeholder="Enter your Username"
          onKeyPress={handleEnter}
          onBlur={(e) => setValue("username", e.target.value.trim())}
        />
        {errors.username && (
          <ErrorElement>{errors.username.message}</ErrorElement>
        )}
        <TitleElement>Password</TitleElement>
        <InputElement
          {...register("password", { required: "Enter your password" })}
          type="password"
          placeholder="Enter your Password"
          onKeyPress={handleEnter}
        />
        {errors.password && (
          <ErrorElement>{errors.password.message}</ErrorElement>
        )}
        <br />
        <br />
        <LargeButton
          primary="true"
          disabled={!isValid}
          loading={isLoading}
          onClick={handleSubmit(onSubmit)}
        >
          Login
        </LargeButton>
        <br />
        {isError && <ErrorElement>{error.message}</ErrorElement>}
      </LoginFormContainer>
    </div>
  );
};

export default Login;

import { Container, TitleElement } from "common/components/StyledElements";
import { Collapse } from "antd";
import { ROLE_CONFIG_KEYS, PERMISSIONS } from "common/constants";
import styled from "styled-components";
import { useAuthContext } from "common/contexts/AuthContext";
import { checkPermission } from "common/utils";
import DistributorRefresh from "./distributor";
import MargRefresh from "./marg";

const RefreshAccordion = () => {
  const { Panel } = Collapse;
  const { parsedRoleConfig } = useAuthContext();
  const updateRefresh = checkPermission(parsedRoleConfig, {
    config: ROLE_CONFIG_KEYS.refresh,
    permission: PERMISSIONS.update
  });
  const PanelContainer = styled.div`
    padding: 4em;
  `;

  return (
    <Container>
      <TitleElement>Refresh</TitleElement>
      <Collapse defaultActiveKey={[""]} destroyInactivePanel>
        {updateRefresh ? (
          <Panel header="Marg" key="1">
            <PanelContainer>
              <MargRefresh />
            </PanelContainer>
          </Panel>
        ) : null}
        {updateRefresh ? (
          <Panel header="Distributor" key="2">
            <PanelContainer>
              <DistributorRefresh />
            </PanelContainer>
          </Panel>
        ) : null}
      </Collapse>
    </Container>
  );
};

export default RefreshAccordion;

import { Image } from "antd";
import { Container } from "common/components/StyledElements";
import { useLocation } from "react-router-dom";

const ImageViewer = () => {
  const location = useLocation();
  return (
    <Container>
      <Image width={400} height={400} src={location?.search?.substring(1)} />
    </Container>
  );
};

export default ImageViewer;

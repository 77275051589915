import { Checkbox, message, Select, Spin } from "antd";
import {
  Button,
  CommonHeading,
  CommonSearchInput,
  ErrorMessage,
  InputElement,
  PageContainer
} from "common/components/StyledElements";
import { PERMISSIONS, ROLE_CONFIG_KEYS } from "common/constants";
import APIConstants from "common/constants/APIConstants";
import COLORS from "common/constants/Colors";
import { useAuthContext } from "common/contexts/AuthContext";
import { checkPermission } from "common/utils";
import { api } from "common/utils/APIMethods";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import styled, { css } from "styled-components";

const { Option } = Select;
const MainContainer = styled(PageContainer)`
  display: flex;
  margin: 0px;
  position: sticky;
`;

const GroupNameHeading = styled.div`
  font-weight: bold;
  font-size: 16px;
`;

const ChildContainer = styled.div`
  padding: 20px;
  width: 50%;
  border: 1px solid ${COLORS.primary_grey};
  border-radius: 8px;
  ${(props) =>
    props.left &&
    css`
      margin-right: 20px;
    `}
`;

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 60vh;
  overflow-y: auto;
  margin-top: 20px;
`;

const CheckBoxContainer = styled.div`
  padding: 10px;
  border: 1px solid ${COLORS.primary_grey};
  border-radius: 6px;
  margin-bottom: 10px;
  cursor: pointer;
  ${(props) =>
    props.checked &&
    css`
      border-color: ${COLORS.primary_green};
    `};
`;

const StyledCheckBox = styled(Checkbox)`
  font-size: 18px;
  font-weight: 600;
`;

const HorizontalContainer = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr;
  align-items: center;
`;

const GroupNameInput = styled(InputElement)`
  height: 40px;
  margin: 0px 20px 20px;
  width: 75%;
  border: 1.5px solid ${COLORS.primary_green};
  border-radius: 6px;
  padding: 16px;
  width: 75%;
  margin-bottom: 0;
`;

const GridErrorMessage = styled.div`
  grid-row: 2;
  grid-column: 2;
  padding-left: 20px;
`;

const UserSearchElement = styled(CommonSearchInput)`
  width: 80%;
  max-width: 80%;
  padding-top: 20px;
  padding-bottom: 20px;
`;

const CommonButton = styled(Button)`
  height: 50px;
  width: 120px;
  ${(props) =>
    props.primary &&
    css`
      margin-left: 40px;
    `}
`;
const ButtonsContainer = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 10px;
  margin-bottom: 20px;
`;

const CreateGroup = (props) => {
  const navigate = useNavigate();
  const { groups = {}, refetch } = props;
  const locationProps = useLocation();
  const queryClient = useQueryClient();
  const { groupId = "", isEditMode = false } = locationProps.state || {};
  const { authConfig, parsedRoleConfig } = useAuthContext();
  const { persistUserGroup, userListById, userAllRole } = APIConstants;
  const group = groups?.content?.filter(
    (value) => value.id === parseInt(groupId, 10)
  )?.[0];
  const [selectedUsers, setSelectedUsers] = useState(
    isEditMode ? group?.members?.map((value) => value.customer.id) || [] : []
  );
  const [searchText, setSearchText] = useState("");
  const [selectedRole, setSelectedRole] = useState(
    isEditMode ? group?.members[0]?.customer?.role?.id : null
  );
  const [allUserList, setAllUserList] = useState([]);
  const [selectedGroupAdmin, setSelectedGroupAdmin] = useState(
    isEditMode ? group?.owner.id : ""
  );
  const groupFilteredRole = [
    "ROLE_PENDING",
    "ROLE_SALESMAN",
    "ROLE_ADMIN",
    "ROLE_CFA_GROUP_ADMIN",
    "ROLE_SUPER_ADMIN"
  ];
  useEffect(() => {
    if (
      parsedRoleConfig &&
      !(
        checkPermission(parsedRoleConfig, {
          config: ROLE_CONFIG_KEYS.group,
          permission: PERMISSIONS.create
        }) ||
        checkPermission(parsedRoleConfig, {
          config: ROLE_CONFIG_KEYS.group,
          permission: PERMISSIONS.update
        })
      )
    ) {
      navigate("/groups");
    }
  }, [navigate, parsedRoleConfig]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm({
    mode: "onChange",
    defaultValues: {
      groupName: isEditMode ? group?.name : ""
    }
  });

  const selectedUserList = useMemo(
    () =>
      group?.members?.map((value) => {
        const {
          customer: {
            address,
            businessName,
            role,
            contactPerson,
            drugLicense,
            drugLicenseKey,
            id,
            phoneNumber,
            uuid
          }
        } = value;
        return {
          address,
          businessName,
          role,
          contactPerson,
          drugLicense,
          drugLicenseKey,
          id,
          phoneNumber,
          uuid
        };
      }),
    [group]
  );
  const { isLoading } = useQuery(
    ["all-users-id", searchText, selectedRole],
    () =>
      api(
        {
          url: `${userListById}/${selectedRole}`,
          params: {
            query: searchText.length > 2 ? searchText : ""
          }
        },
        authConfig
      ),
    {
      enabled: searchText.length > 3,
      onError: (error) => {
        message.error(error);
      },
      onSuccess: (response) => {
        setAllUserList([...allUserList, ...response]);
      }
    }
  );
  const { isGroupLoading, data: allGroupAdmin } = useQuery(
    ["all-group-admin"],
    () =>
      api(
        {
          url: `${userListById}/${4}`,
          params: {
            query: ""
          }
        },
        authConfig
      ),
    {
      onError: (error) => {
        message.error(error);
      }
    }
  );
  const groupAdminList = useMemo(
    () =>
      allGroupAdmin?.map((value) => {
        const { businessName = "", id } = value;
        return {
          businessName: businessName || "",
          id
        };
      }),
    [allGroupAdmin]
  );
  const { isLoading: isPersistingUserGroup, mutate } = useMutation(
    (groupData) =>
      api(
        { url: persistUserGroup, method: "POST", body: groupData },
        authConfig
      ),
    {
      onSuccess: () => {
        message.success("Users group has been created successfully");
        navigate("/groups", { replace: true });
        refetch();
        queryClient.invalidateQueries("groupsList");
      },
      onError: (err) => {
        message.error(
          err?.message ||
            "There was some error while creating the group, Please try again."
        );
      }
    }
  );

  const onSelectUser = (value) => {
    setSelectedUsers([...selectedUsers, value.id]);
  };

  const allUserModifiedList = useMemo(() => {
    const modifiedUsers = isEditMode
      ? [...allUserList, ...selectedUserList]
      : allUserList;
    return Object.values(
      modifiedUsers
        .map((value) => {
          const {
            address,
            businessName,
            role,
            contactPerson,
            drugLicense,
            drugLicenseKey,
            id,
            phoneNumber,
            uuid
          } = value;
          return {
            address,
            businessName,
            role,
            contactPerson,
            drugLicense,
            drugLicenseKey,
            id,
            phoneNumber,
            uuid,
            uuidBusiness: `${uuid} ${businessName}`
          };
        })
        .reduce(
          (accumulator, currentValue) =>
            Object.assign(accumulator, { [currentValue.id]: currentValue }),
          {}
        )
    );
  }, [allUserList, isEditMode, selectedUserList]);

  const onRemoveUser = (value) => {
    const updatedUsers = selectedUsers.filter((id) => id !== value.id);
    setSelectedUsers(updatedUsers);
  };
  const usersList = useMemo(() => {
    const filterUsers = allUserModifiedList
      ?.filter((filtered) => !selectedUsers?.includes(filtered?.id))
      ?.filter((value) =>
        value?.uuidBusiness.toLowerCase().includes(searchText)
      );

    return searchText.length > 3 ? filterUsers : [];
  }, [allUserModifiedList, searchText, selectedUsers]);
  const submit = (formData) => {
    const groupData = {
      groupName: formData.groupName,
      customerIds: selectedUsers,
      groupAdminId: selectedGroupAdmin
    };
    if (isEditMode) {
      groupData.id = groupId;
    }
    mutate(groupData);
  };
  const { data: userRoleList = [] } = useQuery(
    ["userRoleList"],
    () =>
      api(
        {
          url: userAllRole
        },
        authConfig
      ),
    {
      staleTime: Infinity
    }
  );

  return (
    <PageContainer>
      <CommonHeading>
        {`${isEditMode ? "Edit" : "Create"} Group`}
        <Select
          allowClear
          showSearch
          defaultValue={isEditMode ? group?.owner.id : ""}
          onChange={(response) => {
            setSelectedGroupAdmin(response);
          }}
          filterOption={(inputValue, option) =>
            option.props.children
              .toString()
              .toLowerCase()
              .includes(inputValue.toLowerCase())
          }
          loading={isGroupLoading}
          size="large"
          style={{
            width: 200,
            marginLeft: 20
          }}
        >
          {groupAdminList?.map((array) => (
            <Option value={array.id} key={array.id}>
              {array.businessName}
            </Option>
          ))}
        </Select>
        <Select
          allowClear
          showSearch
          defaultValue={
            isEditMode ? group?.members[0]?.customer?.role?.id : null
          }
          disabled={isEditMode}
          onChange={(response) => {
            setSelectedUsers([]);
            setSelectedRole(response || "");
            setSearchText(response ? searchText : "");
          }}
          filterOption={(inputValue, option) =>
            option.props.children
              .toString()
              .toLowerCase()
              .includes(inputValue.toLowerCase())
          }
          loading={isLoading}
          size="large"
          style={{
            width: 200,
            marginLeft: 20
          }}
        >
          {userRoleList?.map((array) => (
            <Option
              value={array.id}
              key={array.id}
              disabled={groupFilteredRole.includes(array.role)}
            >
              {array.role}
            </Option>
          ))}
        </Select>
      </CommonHeading>
      <MainContainer>
        <ChildContainer left>
          <UserSearchElement
            disabled={!selectedRole}
            placeholder="Search Customer Business Name..."
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value.toLowerCase());
            }}
          />
          <ListContainer>
            {isLoading ? (
              <Spin />
            ) : (
              usersList?.map((user) => (
                <CheckBoxContainer
                  key={user?.id}
                  onClick={() => onSelectUser(user)}
                >
                  <StyledCheckBox>{user?.uuidBusiness}</StyledCheckBox>
                </CheckBoxContainer>
              ))
            )}
          </ListContainer>
        </ChildContainer>
        <ChildContainer>
          <HorizontalContainer>
            <GroupNameHeading>Group Name:</GroupNameHeading>
            <GroupNameInput
              {...register("groupName", { required: "Enter Group Name" })}
              onBlur={(e) => setValue("username", e.target.value.trim())}
            />
            <GridErrorMessage>
              {errors.groupName && (
                <ErrorMessage>{errors.groupName.message}</ErrorMessage>
              )}
            </GridErrorMessage>
          </HorizontalContainer>
          <ListContainer>
            {allUserModifiedList
              ?.filter((user) => selectedUsers?.includes(user.id))
              ?.map((user) => (
                <CheckBoxContainer
                  key={user.id}
                  checked
                  onClick={() => onRemoveUser(user)}
                >
                  <StyledCheckBox checked>{user.uuidBusiness}</StyledCheckBox>
                </CheckBoxContainer>
              ))}
          </ListContainer>
        </ChildContainer>
      </MainContainer>
      <ButtonsContainer>
        <CommonButton onClick={() => navigate("/groups")}>Cancel</CommonButton>
        <CommonButton
          primary="true"
          disabled={selectedUsers?.length < 1}
          loading={isPersistingUserGroup}
          onClick={handleSubmit(submit)}
        >
          Save
        </CommonButton>
      </ButtonsContainer>
    </PageContainer>
  );
};

export default CreateGroup;

import { Routes, Route } from "react-router-dom";
import RefreshAccordion from "./refresh-accordion";

const Refresh = () => (
  <Routes>
    <Route index path="/" element={<RefreshAccordion />} />
  </Routes>
);

export default Refresh;

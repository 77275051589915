export const checkPermission = (parsedRoleConfig, { config, permission }) =>
  parsedRoleConfig?.[config]?.indexOf(permission) > -1;

export const filterConfigElements = (roleConfig, elementsConfig = []) => {
  const userElements = elementsConfig
    .filter(
      (elementConfig) =>
        roleConfig?.[elementConfig.permission.key]?.indexOf(
          elementConfig.permission.value
        ) > -1
    )
    .map((elementConfig) => elementConfig.component);
  return userElements;
};

export const debounce = (func, delay, { leading } = {}) => {
  let timerId;

  return (...args) => {
    if (!timerId && leading) {
      func(...args);
    }
    clearTimeout(timerId);

    timerId = setTimeout(() => func(...args), delay);
  };
};
import { createContext, useContext, useMemo, useReducer } from "react";

const AuthContext = createContext();

const initialState = {
  accessToken: window.sessionStorage.getItem("accessToken"),
  sessionId: window.sessionStorage.getItem("sessionId"),
  roleConfig: JSON.parse(window.sessionStorage.getItem("roleConfig")),
  user: JSON.parse(window.sessionStorage.getItem("user")),
  fetchMode: window.sessionStorage.getItem("fetchMode"),
  currentMode: window.sessionStorage.getItem("currentMode")
};

function authReducer(state, action) {
  switch (action.type) {
    case "onRoleSwitch":
    case "onLogin": {
      const { accessToken, refreshToken, role, currentMode } = action.payload;
      const fetchMode = currentMode === "Purchase" ? "supplier" : "customer";
      window.sessionStorage.setItem("accessToken", accessToken);
      window.sessionStorage.setItem("sessionId", refreshToken);
      window.sessionStorage.setItem("roleConfig", JSON.stringify(role));
      window.sessionStorage.setItem("user", JSON.stringify(action.payload));
      window.sessionStorage.setItem("fetchMode", fetchMode);
      window.sessionStorage.setItem("currentMode", currentMode);
      return {
        ...state,
        accessToken,
        sessionId: refreshToken,
        roleConfig: role,
        user: action.payload,
        fetchMode,
        currentMode
      };
    }
    case "onLogout": {
      window.sessionStorage.clear();
      return {
        accessToken: null,
        sessionId: null,
        roleConfig: null,
        user: null
      };
    }
    default:
      throw new Error("Invalid action type");
  }
}

export default function AuthContextProvider({ children }) {
  const [authConfig, dispatch] = useReducer(authReducer, initialState);

  const parsedRoleConfig = useMemo(() => {
    if (authConfig.roleConfig) {
      const { transactionModes } = authConfig.roleConfig;
      const genericConfig = transactionModes.reduce((config, mode) => {
        const modeName = mode.name;
        const modeConfig = mode.features.reduce((accumulator, feature) => {
          const permissions = feature.permissionTypes.map((type) => type.name);
          const featureName = feature.name;
          return { ...accumulator, [featureName]: permissions };
        }, {});
        return { ...config, [modeName]: modeConfig };
      }, {});
      return genericConfig[authConfig.currentMode];
    }
    return null;
  }, [authConfig]);

  const authContext = useMemo(
    () => ({
      authConfig,
      parsedRoleConfig,
      dispatch
    }),
    [authConfig, parsedRoleConfig]
  );

  return (
    <AuthContext.Provider value={authContext}>{children}</AuthContext.Provider>
  );
}

export const useAuthContext = () => useContext(AuthContext);
